.financialStatementContainer {
	position: fixed;
	left: -461px;
	width: 461px;
	background-color: #144E5D;
	top: 0px;
	bottom: 0px;
	overflow-y: scroll;
	transition: left .3s ease;
}
.financialStatementContainer.show {
	left: 0px;
}

/* Header */
.financial-header {
	width: 100%;
	height: 53px;
	padding: 5px;
	display: flex;
}
.financial-forms-tab {
	width: 100%;
	height: 58px;
	padding: 5px;
	display: flex;
}
.financial-header h3 {
	color: #FFFFFF;
	margin: auto 0;
	font-size: 16px;
	font-family: 'NotoSansBold' !important;
}
.financial-header .title-container {
	flex: 2;
	flex-direction: row;
	display: flex;
}
.financial-header .action-container {
	flex: 1;
	flex-direction: row-reverse;
	display: flex;
}
.title-container .closeBtn {
	margin: auto 10px;
	width: 21px;
	height: 21px;
	/*border: 1px solid #FFFFFF;*/
	display: flex;
	cursor: pointer;
}
.title-container .closeBtn .icon {
	color: #FFFFFF;
	margin: auto;
	line-height: 1em;
}
.action-container .ui.basic.grey.button {
	color: #1DFFE5!important;
	box-shadow: 0 0 0 1px #1DFFE5 inset!important;
	font-size: 12px;
	height: 30px;
	width: 94px;
	margin: auto 0;
}

.switch-container {
	display: flex;
	/*flex: 1;*/
}
.switch-container .ui.grid {
	margin: 0px !important;
	min-width: 165px;
	padding-left: 8px !important;
}
.switch-container .ui.grid .row {
	padding-top: 0px;
	height: 32px;
}
.switch-container p {
	color: #ffffff;
	font-size: 13px;
	font-family: 'NotoSans' !important;
	margin: auto;
}
.switch-container .twelve.wide.column {
	display: flex;
}
.switch-container .two.wide.column{
	padding: 0px !important;
	display: flex;
}
.switch-container .two.wide.column img{
	margin: auto;
}

.financial-forms-tab .form-actions {
	flex: 1;
	padding-right: 10px;
}
.financial-forms-tab .form-actions .businessReviewContainer {
	color: #FFFFFF;
	font-size: 13px;
	font-family: 'NotoSans' !important;
	padding-right: 20px;
	text-align: right;
	background-image: url(../assets/Images/disabled-form-arrow-icon.svg);
	background-position-x: right;
	background-position-y: center;
	background-repeat: no-repeat;
	cursor: pointer;
	opacity: 0.25;
}
.financial-forms-tab .form-actions .businessReviewContainer.active {
	opacity: 1 !important;
	background-image: url(../assets/Images/enabled-form-arrow-icon.svg) !important;
}
.financial-forms-tab .form-actions .businessReviewContainer.enabled {
	opacity: 1 !important;
}
.financial-forms-tab .form-actions .cashFlowContainer {
	color: #FFFFFF;
	font-size: 13px;
	font-family: 'NotoSans' !important;
	padding-right: 20px;
	text-align: right;
	background-image: url(../assets/Images/disabled-form-arrow-icon.svg);
	background-position-x: right;
	background-position-y: center;
	background-repeat: no-repeat;
	cursor: pointer;
	opacity: 0.25;
	margin-top: 10px;
}
.financial-forms-tab .form-actions .cashFlowContainer.active {
	opacity: 1 !important;
	background-image: url(../assets/Images/enabled-form-arrow-icon.svg) !important;
}
.financial-forms-tab .form-actions .cashFlowContainer.enabled {
	opacity: 1 !important;
}

.form-row-height {
	height: 34px;
}
.form-display-flex {
	display: flex !important;
}
.form-display-flex-center {
	margin: auto !important;
}
.form-display-flex-v-center {
	margin: auto 0px !important;
}

.form-buttons-header {
	margin: 15px;
	height: 30px;
	display: flex;
}
.form-buttons-header .ui.button {
	flex: 1;
	padding: 0px !important;
}
.form-buttons-header .ui.basic.grey.button, 
.form-buttons-header .ui.basic.grey.buttons .button {
	/*box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.87) inset!important;*/
	box-shadow: none !important;
	border: 1px solid  #E1E8EA;
	border-radius: 4px;
	background-color: rgba(255, 255, 255, 0) !important;
	color: rgba(255, 255, 255, 0.87) !important;
	font-size: 13px !important;
	font-family: 'NotoSans' !important;
	margin: 0px !important;
}

.form-buttons-header .ui.basic.grey.active.button, 
.form-buttons-header .ui.basic.grey.buttons .active.button {
	/* box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.87) inset!important; */
	background-color: #E1E8EA !important;
	color: #464646 !important;
	font-size: 13px !important;
}

.columnBG {
	background-color: rgba(255,255,255, 0.1);
}



.financial-tabs-container {
	height: 61px;
}
.financial-tabs-container .buttons {
	width: 100%;
	height: 100%;
}
.financial-tabs-container .buttons .button {
	background-color: #144E5D;
	font-size: 12px;
	color: #FFFFFF;
	padding: 0px !important;
}
.financial-tabs-container .buttons .button.active {
	background: #FFFFFF;
	color: #144E5D;
}
.financial-actions {
	height: 32px;
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
}
.financial-actions .ui.grid {
	margin: 0px !important;
	padding: 0px !important;
	height: 100%;
	width: 100%;
}
.financial-actions .ui.grid .column {
	padding: 0px !important;
	display: flex;
}
.financial-actions .ui.grid .column p {
	margin: auto;
	color: rgba(255, 255, 255, 0.87);
	font-family: 'NotoSansBold' !important;
	font-size: 12px;
	text-align: center;
}
.financial-actions .ui.grid .column p.title {
	font-size: 14px;
}
.borderTop {
	border-top: 1px solid #979797;
}

.financial-values {
	padding-left: 15px;
	padding-right: 15px;
}
.financial-values .bottomLine {
	border-bottom: 1px solid #979797;
}
.financial-values .ui.grid>.row {
	padding: 0px !important;
}
.financial-values .ui.column {
	margin: 0px !important;
	padding: 0px !important;
}
.financial-values .ui.column .fullHeight {
	height: 100%;
}
.financial-values .ui.column .column {
	padding: 0px !important;
}
.financial-values .ui.column .column p {
	color: #FFFFFF;
	font-size: 12px;
}
.financial-values .ui.column .column p.big {
	font-size: 14px;
}
.financial-values .ui.column .column p.bold {
	font-family: 'NotoSansBold' !important;
}
.financial-values .ui.column .column p.center {
	margin: auto;
	text-align: center;
	/*line-height: 30px;*/
}

.financial-values .textField {
	margin: auto 0px;
	width: 30px;
	height: 30px;
	border: 1px solid #FFFFFF;
	text-align: center;
	background-color: #FFFFFF;
	color: #144E5D;
	font-size: 14px;
	font-family: 'NotoSansBold' !important;
}
.financial-values .textField.error,
.WCField.error,
.cellField.error {
	border: 1px solid #FF0000;
	background-color: #FF0000;
	color: #FFFFFF;
}

.financial-values .center .textField {
	margin: auto !important;
}


.financial-validations {
	margin-top: 20px;
	margin-bottom: 20px;
	display: flex;
}
.players-list{
	display: flex;
	margin-left: 10px;
}
.players-list img {
	padding: 5px;
	margin: auto 0px;
}
.validateSwitch {
	margin: auto 0px auto 10px;
}
.validationStatus {
	color: rgba(255, 255, 255, 0.25);
	margin: auto 0px;
	font-size: 13px;
}
.validationStatus.validating {
	color: #1DFFE5 !important;
}
.submit-form {
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
	cursor: pointer;
}
.flex-space {
	flex: 1
}

.switch-container .ui.toggle.checkbox .box:before,
.switch-container .ui.toggle.checkbox label:before,
.switch-container .ui.toggle.checkbox:focus .box:before {
  border: 2px solid rgba(255, 255, 255, 0.25) !important;
  width: 36px !important;
  height: 18px !important;
}
.switch-container .ui.toggle.checkbox .box:after,
.switch-container .ui.toggle.checkbox label:after {
  /*background-color: rgba(255, 255, 255, 0.25);*/
  width: 18px !important;
  height: 18px !important;
}
.switch-container .ui.checked.toggle.checkbox .box:before,
.switch-container .ui.checked.toggle.checkbox label:before {
  border: 2px solid #1dffe5 !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
.switch-container .ui.checked.toggle.checkbox .box:after,
.switch-container .ui.checked.toggle.checkbox label:after {
  background-color: #1dffe5;
  left: 18px !important;
}
.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: rgba(255, 255, 255, 0) !important;
}