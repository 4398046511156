/* Cash */
.orangeBlock {
	position: relative;
	height: 65.44px;
	background-color: #EF7D00;
	padding: 5px;
}
.orangeBlock .blockArrow {
	position: absolute;
	top: 6px;
	left: -14px;
}
.orangeBlock .blockArrowAr {
	position: absolute;
	top: 6px;
	right: -14px;
}
.orangeBlock .cashGrid {
	height: 100%;
	margin: 0px !important;
}
.orangeBlock .cashRow {
	padding: 0px !important;
}
.orangeBlock .noColumnPadding {
	padding: 0px !important;
	margin: auto !important;
}
.orangeBlock .noColumnPadding.paddingRight {
	padding-right: 10px !important;
}
.orangeBlock .centerContainers {
	width: 100%;
	height: 100%;
	display: flex;
}
.orangeBlock .dottedBorder {
	border-top: 4px dotted #000000;
	opacity: 0.15;
	margin: auto 0;
	flex: 1;
}
.orangeBlock .blockSubTitle {
	font-size: 11px;
	color: #FFFFFF;
	margin: auto;
	font-family: 'NotoSansSCSemiBold' !important;
}
.cashRow img.envelopIcon {
	width: 22px;
	margin: auto 0;
}
.cashRow img.cashIcon {
	width: 20px;
	margin: auto 0;
}
.orangeBlock .columnRightPadding {
	padding-right: 4px !important;
}
.orangeBlock .droppableField {
	min-width: 38px;
	height: 38px;
	border-radius: 19px;
	border: 1px solid #EBEBEC;
	background-color: rgba(0, 0, 0, 0.15);
	box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.16);
	margin: auto;
}
.scaleDown .orangeBlock .droppableField {
	min-width: 28px !important;
	height: 28px !important;
	border-radius: 14px !important;
}
.orangeBlock .droppableField .amountContainer {
	width: 100%;
	height: 100%;
	background-color: #FFFFFF;
	border-radius: 19px;
	padding-bottom: 0px;
	cursor: grab;
}
.orangeBlock .droppableField .amountContainer .coin-value {
	margin: auto;
	font-weight: bold;
	font-size: 17px;
}
.scaleDown .orangeBlock .droppableField .amountContainer .coin-value {
	font-size: 14px !important;
}
.orangeBlock .icon {
	color: #FFFFFF;
	font-size: 22px;
	line-height: 22px;
	margin: auto;
	margin-right: 5px;
}
.orangeBlock .cashRow .centerContent {
	margin: auto 0px !important;
}