.guidedtour-intro-container{
    width: 1131px;
    height: 90%;
    background-color: #144E5D;
    color: white;
    margin-top: 28px;
    margin-bottom: 20px;
    font-family: 'NotoSans';
    display: flex;
}

.guidedtour-intro-container .content-container {
    width: 70%;
    height: 200px;
    margin: auto;
    display: flex;
}
.guidedtour-intro-container .content-container .img-container {
    width: 500px;
    display: flex;
}
.guidedtour-intro-container .content-container .text-container {
    padding: 15px;
}
.guidedtour-intro-container .content-container .img-container img {
    margin: auto;
    width: 70%;
}

.guidedtour-intro-container .header{
    font-size: 18px;
}

.guidedtour-intro-container .text{
    font-size: 12px;
    /*line-height: 14px;*/
}

.guidedtour-intro-container button{
    float: right;
    background-color: transparent;
    border: 1px solid #fff;
    color: inherit;
    border-radius: 5px;
    font-size: 12px;
    width: 94px;
    height: 31px;
}
.repeat-guided-tour {
    width: 120px !important;
}

.guidedtour-intro-container .icons-guide-grid{
    width: 405px;
    height: 75px;
    margin: 30px auto;
}

.guidedtour-intro-container .icons-guide-grid p{
    font-size: 16px;
}


.guidedtour-intro-container .icons-guide-grid img.icon{
    margin: auto;
}