.communityBlock .blockTitle {
	color: rgba(255, 255, 255, 0.5);
	font-family: 'NotoSansBold' !important;
	font-size: 12px;
	text-transform: uppercase;
	padding-left: 5px;
	padding-top: 5px;
}
.communityBlock .atp-row .center {
	margin: auto !important;
}
.communityBlock .atp-row p {
	font-size: 8px;
	color: #FFFFFF;
	text-align: center;
	font-family: 'NotoSansSCBold' !important;
	margin: auto;
}

.communityBlock .droppableField {
	width: 38px;
	height: 38px;
	border-radius: 19px;
	margin: auto;
	background-color: rgba(0, 0, 0, 0.15);
	box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.16);
	border: 1px solid #EBEBEC;
	margin: auto;
}
.scaleDown .communityBlock .droppableField {
	width: 28px !important;
	height: 28px !important;
	border-radius: 14px !important;
}
.communityBlock .droppableField .amountContainer {
	width: 100%;
	height: 100%;
	background-color: #FFFFFF;
	border-radius: 19px;
	padding-bottom: 0px;
	cursor: grab;
}
.communityBlock .droppableField .amountContainer .coin-value {
	margin: auto;
	font-weight: bold;
	font-size: 17px;
	/*cursor: grab;*/
}
.scaleDown .communityBlock .droppableField .amountContainer .coin-value {
	font-size: 14px !important;
}

.communityBlock .icon {
	color: rgba(255, 255, 255, 0.5) !important;
	font-size: 9px !important;
	line-height: 9px !important;
	padding-left: 5px;
}

.communityBlock .flexColumn {
	display: flex !important;
}