.atp-grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: stretch;
	padding: 0;
}
.atp-grid>.atp-row {
	position: relative;
	display: flex;
	flex-direction: row;
	/*flex-wrap: wrap;*/
	justify-content: inherit;
	align-items: stretch;
	width: 100%!important;
	padding: 0;
}
.atp-column {
	position: relative;
	display: inline-block;
	padding: 0;
	vertical-align: top;
}