/* Investment */
.yellowBlock {
	position: relative;
	height: 65.44px;
	margin-bottom: 5px;
	background-color: #E9B400;
	padding: 5px 5px 5px 10px;
}
.yellowBlock .blockArrow {
	position: absolute;
	top: 8px;
	right: -14px;
}
.yellowBlock .blockArrowAr {
	position: absolute;
	top: 8px;
	left: -14px;
}
.yellowBlock .investmentGrid {
	height: 100%;
	margin: 0px !important;
}
.yellowBlock .investmentRow {
	padding: 0px !important;
}
.yellowBlock .noColumnPadding {
	padding: 0px !important;
	margin: auto !important;
}
.yellowBlock .noColumnPadding.paddingRight {
	padding-right: 10px !important;
}
.yellowBlock .blockTitle {
	font-size: 12px;
	font-family: 'NotoSansBold' !important;
	color: rgba(255, 255, 255, 0.8);
	text-transform: uppercase;
	height: 34px;
}
.yellowBlock .blockTitleAr {
	font-size: 12px;
	font-family: 'NotoSansBold' !important;
	color: rgba(255, 255, 255, 0.8);
	text-transform: uppercase;
	height: 34px;
	margin-right: 8px;
}
.yellowBlock .centerContainers {
	width: 100%;
	height: 100%;
	display: flex;
}
.yellowBlock .dottedBorder {
	border-top: 4px dotted #000000;
	opacity: 0.15;
	margin: auto 0;
	flex: 1;
}
.yellowBlock .blockSubTitle {
	font-size: 11px;
	color: #FFFFFF;
	font-family: 'NotoSansSCSemiBold' !important;
}
.investmentRow img {
	width: 24px;
	margin: auto 0;
}
.investmentRow img.propertyIcon {
	width: 32px !important;
}
.yellowBlock .columnRightPadding {
	padding-right: 4px !important;
}
.yellowBlock .columnLeftPadding {
	padding-left: 4px !important;
}
.yellowBlock .droppableField {
	min-width: 38px;
	height: 38px;
	border-radius: 19px;
	border: 1px solid #EBEBEC;
	background-color: rgba(0, 0, 0, 0.15);
	box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.16);
	margin: auto;
}
.scaleDown .yellowBlock .droppableField {
	min-width: 28px !important;
	height: 28px !important;
	border-radius: 14px !important;
}
.yellowBlock .droppableField .amountContainer {
	width: 100%;
	height: 100%;
	background-color: #FFFFFF;
	border-radius: 19px;
	padding-bottom: 0px;
	cursor: grab;
}
.yellowBlock .droppableField .amountContainer .coin-value {
	margin: auto;
	font-weight: bold;
	font-size: 17px;
	/*cursor: grab;*/
}
.scaleDown .yellowBlock .droppableField .amountContainer .coin-value {
	font-size: 14px !important;
}
.yellowBlock .icon {
	color: #FFFFFF;
	font-size: 22px;
	line-height: 22px;
	margin-right: 5px;
}
.yellowBlock .investmentRow .centerContent {
	margin: auto 0px !important;
}
.yellowBlock .icon {
	color: rgba(255, 255, 255, 0.5) !important;
	font-size: 9px !important;
	line-height: 9px !important;
}
