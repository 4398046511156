.workingCapitalContainer > * {
  color: #144e5d !important;
}
.workingCapitalContainer {
  padding: 1rem;
  height: 500px;
  position: relative;
}
.CBleftText {
  position: absolute;
  right: 0;
  width: 40%;
  top: 15px;
}
.CBrightText {
  position: absolute;
  left: 0;
  width: 40%;
  top: 15px;
}

.OleftText {
  position: absolute;
  right: 20px;
  width: 42%;
  bottom: 20px !important;
  opacity: 0.5;
}

.OrightText {
  position: absolute;
  left: 10px;
  width: 42%;
  bottom: 70px !important;
  opacity: 0.5;
}

.columnBGx {
  background-color: rgba(44, 96, 110, 0.1);
}
.lineHeight {
  line-height: 1.5;
}
.padding25 {
  padding: 25px !important;
  margin-bottom: 0 !important;
}
.blueText {
  color: #144e5d !important;
}
.form-display-flex-v-between {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  padding: 4px 8px !important;
}

.WCField {
  width: 34px;
  border: 1px solid #144e5d !important;
  height: 25px;
  margin-top: 3px;
}
.WCField:focus {
  outline: none !important;
}
.w-60 {
  width: 60% !important;
  padding: 7px 0;
}
.borderBottom {
  border-bottom: 1px solid #979797;
}
.paddingLeft {
  padding-left: 26px !important;
}
.paddingLeft20 {
  padding-left: 20px !important;
}
.center {
  text-align: center !important;
}

.minusSign {
  /*font-size: 21px;*/
}

.smallTab {
  padding: 0px 20px !important;
}

.workingCapitalContainer .bold {
  font-family: "NotoSansBold" !important;
}
