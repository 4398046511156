.boardPageContainer {
	position: relative;
	left: 0px;
	right: 0px;
	height: 100%;
	/*-webkit-transition: all .3s ease-in;
	-moz-transition: all .3s ease-in;
	-o-transition: all .3s ease-in;
	-ms-transition: all .3s ease-in;*/
	transition: all .3s ease-in;
}
.boardPageContainer.moveLeft {
	left: 461px !important;
	width: calc(100% - 461px);
}
.boardPageContainer.moveRight {
	right: 250px !important;
	width: calc(100% - 250px);
}


.borardContainer {
	height: calc(100% - 140px);
}
.boardGrid {
	height: calc(100% - 40px);
	margin: 20px auto !important;
}
.boardGridRow {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}
.boardGrid .boardGridRow:first-child {
	height: calc(100% - 92.5px);
}
.boardGrid .boardGridRow.minHeight {
	min-height: 500px;
}
.boardGrid .boardGridRow:last-child {
	height: 92.5px;
	padding-top: 1rem !important;
	padding-bottom: 0px !important;
}
.boardGridRow .ten.column,
.boardGridRow .four.column,
.boardGridRow .two.column:not(.smallBoardPaddingLeft) {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.scaleDown .boardGridRow .eleven.column,
.scaleDown .boardGridRow .five.column {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

/* Board Center Block */
.boardGrid .centerBlockContainer {
	width: calc(100% - 371px);
	height: 100%;
}
.boardGrid.scaleDown .centerBlockContainer {
	width: calc(100% - 256px) !important;
	height: 100%;
}
.boardGrid .centerBlock {
	width: 100%;
	height: 100%;
	border: 7px solid #6C757D;
	padding: 5px;
}
.boardGrid .bottomCenterBlock {
	background-color: #D9D9D9;
	padding: 5px;
}

.whiteBlock {
	height: calc(100% - 309.76px);
	background-color: #FFFFFF;
	display: flex;
	flex-direction: column;
}
.whiteBlock .demoWatermark {
	margin: auto;
}
.whiteBlock .demoWatermark h3 {
	text-align: center;
	color: red;
}
.whiteBlock .demoWatermark img {
	width: 100px;
}

.boardGrid .bottomCenterBlock .grid {
	width: 100%;
	margin: auto !important;
	height: 100%;
}
.boardGrid .bottomCenterBlock .grid > .column:not(.remove-column-padding) {
	padding: 5px !important;
}

.ownersBlock {
	width: 100%;
	height: 100%;
	background-color: #DD0066;
	padding: 5px;
}
.lendersBlock, .communityBlock {
	width: 100%;
	height: 100%;
	background-color: #C35C9A;
	padding: 5px;
}

.remove-column-padding {
	padding: 0px !important;
}


.leftBlockContainer {
	position: relative;
	width: 115px;
	height: 100%;
}
.leftBlockContainer .deliveryArrow {
	position: absolute;
	top: 58.5px;
	right: 0px;
}
.leftBlockContainer .deliveryArrowAr {
	position: absolute;
	top: 58.5px;
	left: 0px;
	transform: scaleX(-1);
}
.leftBlockContainer .purchaseArrow {
	position: absolute;
	bottom: 51px;
	left: 46px;
}
.leftBlockContainer .purchaseArrowAr {
	position: absolute;
	bottom: 51px;
	right: 46px;
	transform: scaleX(-1);
}
.leftBlockContainer .deliveryDotted {
	position: absolute;
	top: 64px;
	height: 0px;
	border-top: 4px dotted #009FE3;
	opacity: 0.5;
	right: 10px;
	left: 46px;
	color: #B2B2B2;
	font-size: 12px;
	padding-left: 5px;
	text-transform: uppercase;
}
.leftBlockContainer .deliveryDottedAr {
	position: absolute;
	top: 64px;
	height: 0px;
	border-top: 4px dotted #009FE3;
	opacity: 0.5;
	left: 10px;
	right: 46px;
	color: #B2B2B2;
	font-size: 12px;
	padding-left: 5px;
	text-transform: uppercase;
}
.leftBlockContainer .deliveryText {
	position: absolute;
	top: 38px;
	left: 62px;
	color: rgba(0, 0, 0, 0.4);
	opacity: 0.84;
	font-size: 10px;
	font-family: 'NotoSansSCMedium';
}
.leftBlockContainer .deliveryTextAr {
	position: absolute;
	top: 38px;
	right: 62px;
	color: rgba(0, 0, 0, 0.4);
	opacity: 0.84;
	font-size: 12px;
	font-family: 'NotoSansSCMedium';
}
.leftBlockContainer .purchaseDotted {
	position: absolute;
	bottom: 54px;
	height: 0px;
	border-top: 4px dotted #EF7D00;
	opacity: 0.5;
	right: 33px;
	left: 56px;
	color: #B2B2B2;
	font-size: 12px;
	padding-left: 5px;
	text-transform: uppercase;
}
.leftBlockContainer .purchaseDottedAr {
	position: absolute;
	bottom: 54px;
	height: 0px;
	border-top: 4px dotted #EF7D00;
	opacity: 0.5;
	left: 33px;
	right: 56px;
	color: #B2B2B2;
	font-size: 12px;
	padding-right: 5px;
	text-transform: uppercase;
	transform: scaleX(-1);
}
.leftBlockContainer .purchaseText {
	position: absolute;
	bottom: 30px;
	left: 62px;
	color: rgba(0, 0, 0, 0.4);
	opacity: 0.84;
	font-size: 10px;
	font-family: 'NotoSansSCMedium';
}
.leftBlockContainer .purchaseTextAr {
	position: absolute;
	bottom: 30px;
	right: 62px;
	color: rgba(0, 0, 0, 0.4);
	opacity: 0.84;
	font-size: 12px;
	font-family: 'NotoSansSCMedium';
}
.leftBlockContainer .productionDotted {
	position: absolute;
	top: 165px;
	bottom: 54px;
	border-left: 4px dotted #EF7D00;
	border-bottom: 4px dotted #EF7D00;
	opacity: 0.5;
	border-radius: 0 0 0 20px;
	right: 5px;
	left: 64%;
}
.leftBlockContainer .productionDottedAr {
	position: absolute;
	top: 165px;
	bottom: 54px;
	border-left: 4px dotted #EF7D00;
	border-bottom: 4px dotted #EF7D00;
	opacity: 0.5;
	border-radius: 0 0 0 20px;
	left: 5px;
	right: 64%;
	transform: scaleX(-1);
}
.leftBlockContainer .productionDotted2 {
	position: absolute;
	top: 64px;
	border-left: 4px dotted #009FE3;
	border-top: 4px dotted #009FE3;
	opacity: 0.5;
	border-radius: 50px 0 0 0;
	right: 10px;
	left: 64%;
	height: 100px;
}
.leftBlockContainer .productionDotted2Ar {
	position: absolute;
	top: 64px;
	border-left: 4px dotted #009FE3;
	border-top: 4px dotted #009FE3;
	opacity: 0.5;
	border-radius: 50px 0 0 0;
	left: 10px;
	right: 64%;
	height: 100px;
	transform: scaleX(-1);
}
.leftBlockContainer .developmentDotted {
	position: absolute;
	top: 140px;
	bottom: 54px;
	border-top: 4px dotted #86BC25;
	border-radius: 50px 0 0 0px;
	right: 0px;
	left: 64%;
}
.leftBlockContainer .developmentDottedAr{
	position: absolute;
	top: 140px;
	bottom: 54px;
	border-top: 4px dotted #86BC25;
	border-radius: 50px 0 0 0px;
	left: 0px;
	right: 64%;
	transform: scaleX(-1);
}
.leftBlockContainer .depreciationDotted {
	position: absolute;
	top: 205px;
	bottom: 54px;
	border-top: 4px dotted #E9B400;
	opacity: 0.5;
	border-radius: 50px 0 0 0;
	right: 0px;
	left: 64%;
}
.leftBlockContainer .depreciationDottedAr {
	position: absolute;
	top: 205px;
	bottom: 54px;
	border-top: 4px dotted #E9B400;
	opacity: 0.5;
	border-radius: 50px 0 0 0;
	left: 0px;
	right: 64%;
	transform: scaleX(-1);

}
.suppliesContainer {
	background-color: #B2B2B2;
	width: 46px;
	height: 100%;
	flex-direction: row;
}
.materialContainer {
	height: 50%;
	color: #FFFFFF;
	display: flex;
	flex-direction: column;
}
.remainingContainer {
	height: 57%;
	color: #FFFFFF;
	display: flex;
	flex-direction: column;
	padding-bottom: 35px;
}
.suppliesText, .customersText {
	color: #FFFFFF;
	display: flex;
}
.suppliesText {
	height: 50%;
} 
.suppliesText.Ar{
	align-items: flex-end;
}
.customersText {
	height: 43%;
}
.customersText.Ar {
	height: 43%;
	align-items: flex-start;
	padding-top: 20px;
}
.materialContainer .matrialBlock {
	border: 0.5px solid #FFFFFF;
	background-color: rgba(0, 0, 0, 0.15);
	border-radius: 2px;
	width: 26.5px;
	height: 26.5px;
	margin: auto;
	margin-bottom: 0px;
	margin-top: 53px;
}
.materialContainer .matrialBlock .materialDrop {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.suppliersHasContainer {
	background-color: #FFFFFF;
	color: black;
	font-size: 17px;
	font-weight: bold;
}
.remainingContainer .droppableField {
	background-color: rgba(0, 0, 0, 0.15);
	border-radius: 19px;
	width: 38px !important;
	height: 38px !important;
	margin: auto;
	margin-bottom: 0px;
	margin-top: 10px;
}
.remainingContainer .droppableField .amountContainer {
	width: 100%;
	height: 100%;
	background-color: #FFFFFF;
	border-radius: 19px;
	padding-bottom: 0px;
	cursor: grab;
}
.remainingContainer .droppableField .amountContainer .coin-value {
	margin: auto;
	font-weight: bold;
	font-size: 17px;
	color: black;
}

.materialContainer p {
	font-size: 11px;
	font-family: 'NotoSansSCSemiBold';
	margin: auto;
	margin-top: 10px;
	writing-mode: vertical-rl;
	transform: rotate(-180deg);
	/* Safari */
	-webkit-transform: rotate(-180deg);
	/* Firefox */
	-moz-transform: rotate(-180deg);
	/* IE */
	-ms-transform: rotate(-180deg);
	/* Opera */
	-o-transform: rotate(-180deg);
	line-height: 12px;
}
.suppliesText p {
	font-size: 12px;
	line-height: 12px;
	font-family: 'NotoSansBold';
	color: #FFFFFF;
	opacity: 0.5;
	text-transform: uppercase;
	margin: 18px auto;
	writing-mode: vertical-rl;
	transform: rotate(-180deg);
	/* Safari */
	-webkit-transform: rotate(-180deg);
	/* Firefox */
	-moz-transform: rotate(-180deg);
	/* IE */
	-ms-transform: rotate(-180deg);
	/* Opera */
	-o-transform: rotate(-180deg);
}
.customersText p {
	text-transform: uppercase;
	margin: 18px auto;
	writing-mode: vertical-rl;
	font-size: 12px;
	font-family: 'NotoSansBold';
	opacity: 0.5;
	line-height: 12px;
}
.remainingContainer p {
	writing-mode: vertical-rl;
	margin: auto;
	font-size: 11px;
	font-family: 'NotoSansSCSemiBold';
	margin-bottom: 0px;
}



.rightBlocksContainers {
	float: left;
	width: 256px;
	height: 100%;
	/*padding: 12px 5px 12px 5px;*/
	display: flex !important;
	/*align-items: flex-end;*/
	flex-direction: row;
}
/* .scaleDown .rightBlocksContainers {
	padding: 12px 0px 12px 5px !important;
} */
.rightBlocks {
	width: calc(100% - 46px);
	height: 100%;
}
.customersContainer {
	background-color: #B2B2B2;
	width: 46px;
	height: 100%;
}
.blocksContainer {
	position: relative;
	width: 100%;
	height: calc(100% - 76px);
	margin-bottom: 5px;
}

.salesDashContainer {
	height: 65.44px;
	width: 100%;
	position: relative;
	overflow: hidden;
}
.salesDashContainer .borderBox {
	border-top: 4px dotted #EF7D00;
	opacity: 0.5;
	width: 100%;
	position: relative;
	top: 16px;
	margin-left: 10px;
}
.salesDashContainer .borderBoxAr {
	border-top: 4px dotted #EF7D00;
	opacity: 0.5;
	width: 100%;
	position: relative;
	top: 16px;
	margin-right: 10px;
}
.salesDashContainer .text {
	color: rgba(0, 0, 0, 0.4);
	opacity: 0.84;
	position: absolute;
	bottom: 22px;
	right: 15px;
	font-size: 10px;
	font-family: 'NotoSansSCMedium';
}
.salesDashContainer .textAr {
	color: rgba(0, 0, 0, 0.4);
	opacity: 0.84;
	position: absolute;
	bottom: 22px;
	left: 15px;
	font-size: 12px;
	font-family: 'NotoSansSCMedium';
}
.salesDashContainer .purchaseArrow {
	position: absolute;
	top: 13px;
	left: 0px;
}
.salesDashContainer .purchaseArrowAr {
	position: absolute;
	top: 13px;
	right: 0px;
	transform: scaleX(-1);
}
.blocksContainer .productionDotted {
	position: absolute;
	top: 63px;
	bottom: 193px;
	border-right: 4px dotted #009FE3;
	border-top: 4px dotted #009FE3;
	opacity: 0.5;
	border-radius: 0 20px 0 0;
	right: 15%;
	left: 0px;
}
.blocksContainer .productionDottedAr {
	position: absolute;
	top: 63px;
	bottom: 193px;
	border-right: 4px dotted #009FE3;
	border-top: 4px dotted #009FE3;
	opacity: 0.5;
	border-radius: 0 20px 0 0;
	left: 15%;
	right: 0px;
	transform: scaleX(-1);
}
.blocksContainer .productionArrowDotted {
	position: absolute;
	top: 63px;
	height: 4px;
	border-top: 4px dotted #009FE3;
	opacity: 0.5;
	right: 10px;
	left: 84%;
}
.blocksContainer .productionArrowDottedAr {
	position: absolute;
	top: 63px;
	height: 4px;
	border-top: 4px dotted #009FE3;
	opacity: 0.5;
	left: 10px;
	right: 84%;
	transform: scaleX(-1);
}
.blocksContainer .productionArrow {
	position: absolute;
	top: 57.5px;
	right: 0px;
}
.blocksContainer .productionArrowAr {
	position: absolute;
	top: 57.5px;
	left: 0px;
	transform: scaleX(-1);
}
.blocksContainer .developmentDotted {
	position: absolute;
	top: 136px;
	bottom: 163px;
	border-right: 4px dotted #86BC25;
	border-top: 4px dotted #86BC25;
	opacity: 0.5;
	border-radius: 0 20px 0 0;
	right: 50%;
	left: 0px;
}
.blocksContainer .developmentDottedAr {
	position: absolute;
	top: 136px;
	bottom: 163px;
	border-right: 4px dotted #86BC25;
	border-top: 4px dotted #86BC25;
	opacity: 0.5;
	border-radius: 0 20px 0 0;
	left: 50%;
	right: 0px;
	transform: scaleX(-1);
}
.blocksContainer .depreciationDotted {
	position: absolute;
	top: 206px;
	bottom: 76px;
	border-right: 4px dotted #E9B400;
	border-top: 4px dotted #E9B400;
	opacity: 0.5;
	border-radius: 0 20px 0 0;
	right: 82%;
	left: 0px;
}
.blocksContainer .depreciationDottedAr {
	position: absolute;
	top: 206px;
	bottom: 76px;
	border-right: 4px dotted #E9B400;
	border-top: 4px dotted #E9B400;
	opacity: 0.5;
	border-radius: 0 20px 0 0;
	left: 82%;
	right: 0px;
	transform: scaleX(-1);
}
.blocksContainer .three.column {
	height: 100%;
	margin: 0px !important;
	padding-left: 3px !important;
}
.blocksContainer .depreciationContainer,
.blocksContainer .developmentContainer,
.blocksContainer .costOfSalesContainer {
	padding: 0px !important;
	padding-right: 3px !important;
}
.blocksContainer .depreciationContainer {
	display: flex !important;
	align-items: flex-end !important;
}
.blocksContainer .depreciationBlock {
	background-color: #E9B400;
	height: 83.47px;
	width: 100%;
	padding: 2px;
	padding-bottom: 5px;
}

.blocksContainer .developmentContainer {
	display: flex !important;
	align-items: flex-end !important;
	flex-direction: column-reverse;
}
.blocksContainer .developmentContainer .overheadBlock,
.blocksContainer .developmentContainer .developmentBlock {
	background-color: #86BC25;
	height: 83.47px;
	width: 100%;
	padding: 2px;
	padding-bottom: 5px;
}
.blocksContainer .developmentBlock {
	margin-bottom: 5px;
}

.blocksContainer .costOfSalesContainer {
	display: flex !important;
	align-items: flex-end !important;
}
.blocksContainer .costOfSalesContainer .costOfSalesBlock {
	background-color: #009FE3;
	width: 100%;
	padding: 5px;
	color: #FFFFFF;
	font-size: 8px;
	text-align: center;
	font-family: 'NotoSansSCBold';
}
.costOfSalesBlock .column {
	padding: 0px !important;
	margin: 0px;
}
.costOfSalesBlock .row {
	padding-top: 0px !important;
	padding-bottom: 5px !important;
}

.costOfSalesBlock p{
	width: 45px;
    text-align: center;
    margin: 0 auto 9px auto;
}

.costOfSalesBlock .droppableField {
	width: 24px;
	height: 24px;
	border-radius: 12px;
	margin: auto;
	background-color: rgba(0, 0, 0, 0.15);
	box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.16);
	border: 1px solid #EBEBEC;
}
.scaleDown .costOfSalesBlock .droppableField {
	width: 24px !important;
	height: 24px !important;
	border-radius: 12px !important;
}
.costOfSalesBlock .droppableField .amountContainer {
	width: 100%;
	height: 100%;
	background-color: #FFFFFF;
	border-radius: 12px;
	padding-bottom: 0px;
	cursor: grab;
}
.costOfSalesBlock .droppableField .amountContainer .coin-value {
	margin: auto;
	font-weight: bold;
	font-size: 12px;
	color: black;
}
.scaleDown .costOfSalesBlock .droppableField .amountContainer .coin-value {
	font-size: 10px !important;
}


.developmentBlock .droppableField,
.overheadBlock .droppableField,
.depreciationBlock .droppableField {
	width: 34px;
	height: 34px;
	border-radius: 17px;
	margin: auto;
	background-color: rgba(0, 0, 0, 0.15);
	box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.16);
	border: 1px solid #EBEBEC;
}
.scaleDown .developmentBlock .droppableField,
.scaleDown .overheadBlock .droppableField,
.scaleDown .depreciationBlock .droppableField {
	width: 28px !important;
	height: 28px !important;
	border-radius: 14px !important;
}
.depreciationBlock .droppableField .amountContainer,
.developmentBlock .droppableField .amountContainer,
.overheadBlock .droppableField .amountContainer {
	width: 100%;
	height: 100%;
	background-color: #FFFFFF;
	border-radius: 17px;
	padding-bottom: 0px;
	cursor: grab;
}
.depreciationBlock .droppableField .amountContainer .coin-value,
.developmentBlock .droppableField .amountContainer .coin-value,
.overheadBlock .droppableField .amountContainer .coin-value {
	margin: auto;
	font-weight: bold;
	font-size: 17px;
	/*cursor: grab;*/
}
.scaleDown .depreciationBlock .droppableField .amountContainer .coin-value,
.scaleDown .developmentBlock .droppableField .amountContainer .coin-value,
.scaleDown .overheadBlock .droppableField .amountContainer .coin-value {
	font-size: 14px !important;
}


.depreciationGrid,
.developmentBlockGrid,
.overheadGrid {
	margin: 0px !important;
	padding: 0px !important;
	height: 100%;
}
.depreciationGrid .column,
.developmentBlockGrid .column,
.overheadGrid .column {
	padding: 0px !important;
	margin: auto;
}
.depreciationGrid p,
.developmentBlockGrid p,
.overheadGrid p {
	color: #FFFFFF;
	font-size: 8px;
	font-family: 'NotoSansSCBold';
	margin: auto;
	text-align: center;
}
/*.scaleDown .depreciationGrid p,
.scaleDown .developmentBlockGrid p,
.scaleDown .overheadGrid p {
	font-size: 7px !important;
}*/
.depreciationGrid .row,
.developmentBlockGrid .row,
.overheadGrid .row {
	padding: 0px !important;
	margin: auto 0px !important;
}


.goodsSoldColumn {
	margin-bottom: 5px !important;
}


.boardCelemiLogo, 
.boardCelemiLogoAr {
	position: relative !important;
	width: 256px;
}
.boardCelemiLogo img {
	position: absolute !important;
	right: 0px;
	bottom: 0px;
}
.boardCelemiLogoAr img {
	position: absolute !important;
	left: 0px;
	bottom: 0px;
}




.coinsPopup {
	position: absolute;
	background-color: rgba(235, 234, 234, 0.8);
	width: 110px;
	height: 85px;
	border-radius: 10px;
	top: -75px;
	left: -35px;
	z-index: 1000;
}
.coinsPopup .closeContainer {
	height: 20px;
	display: flex;
	flex-direction: row-reverse;
}
.coinsPopup .closeContainer .closeBtn {
	width: 25px;
	display: flex;
	cursor: pointer;
}
.coinsPopup .closeContainer img {
	font-size: 14px;
	margin: auto;
	color: #144E5D !important;
}
.coinsPopup .valuesActions {
	height: 44px;
	display: flex;
	flex-direction: row;
}
.coinsPopup .containerValueContainer {
	width: 50%;
	display: flex;
}
.coinsPopup .containerValueContainer .draggableContainer {
	background-color: #FFFFFF;
	border-radius: 19px;
	width: 38px !important;
	height: 38px !important;
	margin: auto;
	display: flex;
	box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.16);
	cursor: grab;
}
.coinsPopup .containerValueContainer .draggableContainer .coin-value {
	margin: auto;
	font-weight: bold;
	font-size: 17px;
	color: black;
}

.coinsPopup .decreaseContainer, 
.coinsPopup .increaseContainer {
	display: flex;
	width: 25%;
}

.coinsPopup .decreaseBtn,
.coinsPopup .increaseBtn {
	display: flex;
	color: #144E5D;
	margin: auto;
	cursor: pointer;
}
.coinsPopup .decreaseBtn .icon,
.coinsPopup .increaseBtn .icon {
	font-size: 14px;
	margin: auto;
	color: #144E5D !important;
}
.coinsPopup .decreaseBtn .icon.disable {
	color: #cccccc !important;
}


.blocksContainer .text {
	color: rgba(0, 0, 0, 0.4);
	position: absolute;
	top: 40px;
	right: 15px;
	font-size: 10px;
	font-family: 'NotoSansSCMedium';
	opacity: 0.84;
}
.blocksContainer .textAr {
	color: rgba(0, 0, 0, 0.4);
	position: absolute;
	top: 40px;
	left: 15px;
	font-size: 12px;
	font-family: 'NotoSansSCMedium';
	opacity: 0.84;
}


.blueBlock .coinsPopup .containerValueContainer .draggableContainer {
	border-radius: 4px !important;
	border: 1px solid #EBEBEC;
}

.coinValueOverlay {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #FFFFFF;
	font-size: 17px;
	font-weight: bold;
	text-align: center;
	display: none;
	align-items: center;
	justify-content: center;
}
.stockOfMaterial:hover .coinValueOverlay,
.manufacturing:hover .coinValueOverlay,
.assembly:hover .coinValueOverlay,
.finishedGoods:hover .coinValueOverlay {
	display: flex !important;
	z-index: 10;
}


.depreciationBlock i,
.overheadBlock i,
.costOfSalesBlock i,
.developmentBlock i {
	font-size: 9px;
	line-height: 9px;
	color: #FFFFFF;
	opacity: 0.5;
}

.current-checklist-container .column.checklistDesc {
	margin-top: 0px !important;
}



.dragging {
	opacity: 0.5;
}
.glow {
	box-shadow: 0px 0px 6px #FFFFFF !important;
}

.blueBlock .glow.droppableField .amountContainer,
.blueBlock .glow.droppableField .amountContainer .coinsContainer,
.yellowBlock .glow.droppableField .amountContainer,
.depreciationBlock .glow.droppableField .amountContainer,
.developmentBlock .glow.droppableField .amountContainer,
.costOfSalesBlock .glow.droppableField .amountContainer,
.remainingContainer .glow.droppableField .amountContainer,
.orangeBlock .glow.droppableField .amountContainer,
.ownersBlock .glow.droppableField .amountContainer,
.lendersBlock .glow.droppableField .amountContainer,
.communityBlock .glow.droppableField .amountContainer {
	background-color: #DAFEEF !important;
}


.moveLeft .ui.container {
	padding-right: 7px;
}


.coinValueOverlay {
	cursor: none !important;
}