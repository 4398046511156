/* Development */
.greenBlock {
	position: relative;
	height: 65.44px;
	margin-bottom: 5px;
	background-color: #86BC25;
	padding: 5px 5px 5px 10px;
}
.greenBlock .blockArrow {
	position: absolute;
	top: 10px;
	right: -14px;
}
.greenBlock .blockArrowAr {
	position: absolute;
	top: 10px;
	left: -14px;
}
.greenBlock .developmentGrid {
	height: 100%;
	margin: 0px !important;
}
.greenBlock .developmentRow {
	padding: 0px !important;
}
.greenBlock .noColumnPadding {
	padding: 0px !important;
	margin: auto !important;
}
.greenBlock .blockTitle {
	font-size: 12px;
	font-family: 'NotoSansBold' !important;
	color: rgba(255, 255, 255, 0.5);
	text-transform: uppercase;
	width: 80%;
}
.greenBlock .blockTitleAr {
	font-size: 12px;
	font-family: 'NotoSansBold' !important;
	color: rgba(255, 255, 255, 0.5);
	text-transform: uppercase;
	width: 80%;
	margin-right: 8px;
}
.greenBlock .centerContainers {
	width: 100%;
	height: 100%;
	display: flex;
}
.greenBlock .dottedBorder {
	border-top: 4px dotted #000000;
	opacity: 0.15;
	margin: auto 0;
	flex: 1;
}
.greenBlock .blockSubTitle {
	font-size: 11px;
	color: #FFFFFF;
	font-family: 'NotoSansSCSemiBold' !important;
}
.developmentRow img {
	width: 24px;
	margin: auto 0;
}
.developmentRow img.handshakeIcon {
	width: 32px;
}
.greenBlock .icon {
	color: #FFFFFF;
	font-size: 22px;
	line-height: 22px;
	margin-right: 5px;
}
.developmentRow .icon {
	color: rgba(255, 255, 255, 0.5) !important;
	font-size: 9px !important;
	line-height: 9px !important;
}

/*.greenBlock img.ui.image {
	margin: auto;
}*/