.smallBoardGrid {
	height: calc(100% - 40px);
	margin: 20px auto !important;
}
.smallBoardGrid .boardGridRow:first-child {
	height: calc(100% - 72.5px);
}
.smallBoardGrid .boardGridRow:last-child {
	height: 72.5px;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}
.boardGridRow .ten.column,
.boardGridRow .four.column,
.boardGridRow .two.column:not(.smallBoardPaddingLeft) {
	padding-left: 0px !important;
	padding-right: 0px !important;
}


.smallBoardGrid .centerBlock {
	width: 100%;
	height: 100%;
	max-height: 430px;
	background-color: #D9D9D9;
	padding: 5px;
}
.smallBoardGrid .rightBlock {
	width: 100%;
	height: 100%;
	padding: 5px;
}

.smallBoardGrid .productionBlock {
	position: relative;
	background-color: #009FE3;
	height: 174.16px;
	margin-bottom: 5px;
	padding: 5px;
}
.smallBoardGrid .productionBlock .blockArrow {
	position: absolute;
	right: -14px;
	bottom: 58px;
}

.smallBoardGrid .developmentBlock {
	position: relative;
	background-color: #86BC25;
	height: 76.64px;
	margin-bottom: 5px;
	padding: 5px;
}
.smallBoardGrid .developmentBlock .blockArrow {
	position: absolute;
	right: -14px;
	bottom: calc(50% - 14px);
}

.smallBoardGrid .investmentBlock {
	position: relative;
	background-color: #E9B400;
	height: 76.64px;
	margin-bottom: 5px;
	padding: 5px;
}
.smallBoardGrid .investmentBlock .blockArrow {
	position: absolute;
	right: -14px;
	bottom: calc(50% - 5px);
}

.smallBoardGrid .cashBlock {
	position: relative;
	background-color: #EF7D00;
	height: 76.64px;
	padding: 5px;
}
.smallBoardGrid .cashBlock .blockArrow {
	position: absolute;
	left: -14px;
	bottom: calc(50% - 4px);
}

.smallBoardGrid .rmpBlock {
	position: relative;
	background-color: #A7A9AC;
	height: 76.64px;
	padding: 5px;
}
.smallBoardGrid .rmpBlock .blockArrow {
	position: absolute;
	left: -11px;
	bottom: calc(50% - 4px);
}

.smallBoardGrid .development {
	display: flex;
	flex-direction: row;
}
.smallBoardGrid .development .developmentBlock {
	flex: 1
}
.smallBoardGrid .development .developmentBlock:first {
	margin-right: 3px;
}
.smallBoardGrid .development .developmentBlock:last-child {
	margin-left: 3px;
}

.smallBoardGrid .blockTitle {
	color: rgba(255, 255, 255, 0.8) !important;
	font-size: 12px !important;
	font-weight: bold !important;
	text-transform: uppercase;
	margin: auto;
}

/* Production */
.smallBoardGrid .productionGrid {
	margin: 0px !important;
	padding: 0px !important;
}
.smallBoardGrid .productionGrid .noColumnPadding {
	padding: 0px !important;
	margin: auto !important;
}
.smallBoardGrid .productionGrid .centerContainers {
	width: 100%;
	height: 100%;
	display: flex;
}
.smallBoardGrid .productionGrid .stockOfMatrialsContainer {
	display: flex;
	margin: 0 auto;
}
.smallBoardGrid .productionGrid .droppableContainer {
	width: 44px;
	height: 44px;
	border: 1px solid #FFFFFF;
	background-color: #FFFFFF;
	border-radius: 22px;
}
.smallBoardGrid .productionGrid .dottedBorder {
	border-top: 4px dotted #000000;
	opacity: 0.25;
}
.smallBoardGrid .productionGrid .smallSubtitle {
	color: #FFFFFF;
	font-size: 10px;
	opacity: 0.7;
	font-weight: bold;;
}



/* Development */
.smallBoardGrid .developmentGrid {
	margin: 0px !important;
	padding: 0px !important;
	height: 100%;
}
.smallBoardGrid .developmentGrid .row {
	padding: 0px !important;
}
.smallBoardGrid .developmentGrid .noColumnPadding {
	padding: 0px !important;
	margin: auto !important;
}
.smallBoardGrid .developmentGrid .dottedBorder {
	border-top: 4px dotted #000000;
	opacity: 0.25;
}
.smallBoardGrid .developmentGrid .droppableContainer {
	width: 44px;
	height: 44px;
	border: 1px solid #FFFFFF;
	background-color: #FFFFFF;
	border-radius: 22px;
	margin: auto;
}
.smallBoardGrid .developmentGrid .smallSubtitle {
	color: #FFFFFF;
	font-size: 10px;
	opacity: 0.7;
	font-weight: bold;
	text-align: center;
}
.flexDiv {
	display: flex !important;
}

/* Investment */
.smallBoardGrid .investmentGrid {
	margin: 0px !important;
	padding: 0px !important;
	height: 100%;
}
.smallBoardGrid .investmentGrid .row {
	padding: 0px !important;
}
.smallBoardGrid .investmentGrid .noColumnPadding {
	padding: 0px !important;
	margin: auto !important;
}
.smallBoardGrid .investmentGrid .dottedBorder {
	border-top: 4px dotted #000000;
	opacity: 0.25;
}
.smallBoardGrid .investmentGrid .droppableContainer {
	width: 44px;
	height: 44px;
	border: 1px solid #FFFFFF;
	background-color: #FFFFFF;
	border-radius: 22px;
	margin: auto;
}
.smallBoardGrid .investmentGrid .smallSubtitle {
	color: #FFFFFF;
	font-size: 10px;
	opacity: 0.7;
	font-weight: bold;
	text-align: center;
}


/* Cash */
.smallBoardGrid .cashGrid {
	margin: 0px !important;
	padding: 0px !important;
}
.smallBoardGrid .cashGrid .row {
	padding: 0px !important;
}
.smallBoardGrid .cashGrid .noColumnPadding {
	padding: 0px !important;
	margin: auto !important;
}
.smallBoardGrid .cashGrid .dottedBorder {
	border-top: 4px dotted #000000;
	opacity: 0.25;
}
.smallBoardGrid .cashGrid .droppableContainer {
	width: 44px;
	height: 44px;
	border: 1px solid #FFFFFF;
	background-color: #FFFFFF;
	border-radius: 22px;
	margin: auto;
}
.smallBoardGrid .cashGrid .smallSubtitle {
	color: #FFFFFF;
	font-size: 10px;
	opacity: 0.7;
	font-weight: bold;
	text-align: center;
}

/* rmpBlock */
.smallBoardGrid .rmpGrid {
	margin: 0px !important;
	padding: 0px !important;
	height: 100%;
}
.smallBoardGrid .rmpGrid .row {
	padding: 0px !important;
}
.smallBoardGrid .rmpGrid .noColumnPadding {
	padding: 0px !important;
	margin: auto !important;
}
.smallBoardGrid .rmpGrid .dottedBorder {
	border-top: 4px dotted #000000;
	opacity: 0.25;
}
.smallBoardGrid .rmpGrid .droppableContainer {
	width: 44px;
	height: 44px;
	border: 1px solid #FFFFFF;
	background-color: #FFFFFF;
	border-radius: 22px;
	margin: auto;
}
.smallBoardGrid .rmpGrid .smallSubtitle {
	color: #FFFFFF;
	font-size: 10px;
	opacity: 0.7;
	font-weight: bold;
	text-align: center;
}


/* Bottom */
.smallBoardGrid .bottomCenterBlock {
	width: 100%;
	height: 100%;
	background-color: #D9D9D9;
}
.smallBoardGrid .bottomCenterBlock .grid {
	width: 100%;
	margin: auto !important;
	height: 100%;
}
.smallBoardGrid .bottomCenterBlock .grid > .column:not(.remove-column-padding) {
	padding: 5px !important;
}
