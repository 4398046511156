.indicatorsGrid > .row {
  margin: 0 !important;
  padding: 0 !important;
  border-bottom: 2px solid #d6d6d6 !important;
}
.indicator1stCol {
  color: #144e5d;
  padding: 34px 20px 34px 40px !important;
}
.indicator1stCol.ar {
  padding: 34px 40px 34px 20px !important;
}
.indicatorsGrid > .row > .column > h2 {
  font-size: 18px !important;
  margin-bottom: -11px !important;
  font-family: "NotoSansBold" !important;
}
.indicatorsGrid > .row > .column > h4 {
  font-size: 13px !important;
  font-weight: 300 !important;
  line-height: 1.5;
  width: 93%;
}
.colEquation {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15px;
}
.colEquation > div:nth-of-type(1) {
  width: 108px;
  margin-right: 20px;
}
.colEquation.year1 > div:nth-of-type(1) {
  width: 145px;
  margin-right: 20px;
}
.colEquation > div:nth-of-type(2) {
  width: 40px;
  text-align: center;
}

.indicator2ndCol, .indicator2ndCol1stYr {
  color: #144e5d;
}

.indicator2ndCol > span,
.indicator3rdCol > span,
.indicator2ndCol1stYr > span {
  margin-right: 18px;
  font-family: "NotoSansBold";
  font-size: 13px;
}

.indicator3rdCol.ar > span,
.indicator2ndCol1stYr.ar > span {
  margin-right: 22px;
  font-family: "NotoSansBold";
  font-size: 13px;
}

.indicator2ndCol.ar > span{
  margin-left: 35px;
  margin-right: 0;
}

.indicator2ndCol {
  padding-top: 34px !important;
  text-align: end !important;
}

.indicator2ndCol1stYr {
  padding-top: 66px !important;
  text-align: end !important;
}

.indicator3rdCol {
  padding-top: 34px !important;
  color: white !important;
  background-color: #144e5d;
}

.indicatorField {
  width: 40px;
  height: 23px;
  border-color: transparent !important;
}
.indicatorField:focus {
  outline: none !important;
}

.indicatorField.error {
  background-color: #ff0000;
  color: white;
}

hr.divider {
  border: 0;
  border-top: 1px solid #ccc;
  padding: 0;
}
