/* Production */
.blueBlock {
	position: relative;
	height: 98.44px;
	margin-bottom: 5px;
	background-color: #009FE3;
	padding: 5px 10px 5px 10px;
}
.blueBlock .blockArrow {
	position: absolute;
	top: 40px;
	right: -14px;
}
.blueBlock .blockArrowAr {
	position: absolute;
	top: 40px;
	left: -14px;
}
.blueBlock .gridNoMargin {
	margin: 0px !important;
}
.blueBlock .blockTitle {
	color: rgba(255, 255, 255, 0.5) !important;
	font-size: 12px !important;
	font-family: 'NotoSansBold' !important;
	text-transform: uppercase;
	line-height: 12px;
	display: flex;
	padding-top: 5px;
	margin-bottom: 0px;
}
.blueBlock .blockTitle .icon {
	color: rgba(255, 255, 255, 0.5) !important;
	font-size: 9px !important;
	line-height: 9px !important;
	margin-left: .55rem;
}
.dotContainer {
	margin: auto !important;
}
.blueBlock .dottedBorder {
	border-top: 4px dotted #000000;
	opacity: 0.15;
	width: 100%;
	margin: auto;
}
.blueBlock .smallSubtitle {
	color: #FFFFFF;
	font-size: 10px;
	opacity: 0.5;
	text-align: center;
	font-family: 'NotoSansSCBold';
}
.blueBlock .containerText {
	color: #FFFFFF;
	font-size: 11px;
	font-family: 'NotoSansSCSemiBold' !important;
	margin: auto 0px;
}
.blueBlock .centerContainerText {
	color: #FFFFFF;
	font-size: 11px;
	font-family: 'NotoSansSCSemiBold' !important;
	margin: auto;
}
.blueBlock .row {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}
.blueBlock .noColumnPadding {
	padding: 0px !important;
	margin: auto !important;
}
.blueBlock .containersContainer {
	height: 40px;
}
.blueBlock .centerContainers {
	width: 100%;
	height: 100%;
	display: flex;
}
.blueBlock .stockOfMatrialsContainer,
.blueBlock .manufacturingContainer {
	display: flex;
	margin: 0 auto;
}

.scaleDown .smallBoardPaddingLeft {
	padding-left: 10px !important;
	padding-right: 0px !important;
}

/*.blueBlock .droppableField .amountContainer::before {
	content: '0';
	background-color: #FFFFFF;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	margin-top: -8px;
	position: absolute;
	text-align: center;
	font-size: 0px;
	left: 0px;
	border: 1px solid #EBEBEC;
}*/

.blueBlock .droppableField .amountContainer .coinsContainer {
	background-color: white;
	z-index: 5;
	position: relative;
	padding-bottom: 2px;
	border-radius: 2px;
}
.blueBlock .droppableFieldAr .amountContainer .coinsContainer {
	background-color: white;
	z-index: 5;
	position: relative;
	padding-bottom: 2px;
	border-radius: 2px;
}

.manufacturingContainer .droppableField:first-child {
	margin-left: 8px;
}
.manufacturingContainer .droppableFieldAr:first-child {
	margin-right: 8px;
}


/* ui improvments */
.flex-container{
	display: flex;
    justify-content: flex-end;
}

.close-button{
	transform: rotate(45deg);
	font-size: 15px;
	opacity: 0.25;
	cursor: pointer;
}