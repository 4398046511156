@font-face {
	font-family: 'NotoSans';
	src: url('../assets/Fonts/Noto_Sans/NotoSans-Regular.ttf')  format('truetype') /* Safari, Android, iOS */
}
@font-face {
	font-family: 'NotoSansBold';
	src: url('../assets/Fonts/Noto_Sans/NotoSans-Bold.ttf')  format('truetype') /* Safari, Android, iOS */
}
@font-face {
	font-family: 'NotoSansSC';
	src: url('../assets//Fonts//Noto_Sans_SC/New/NotoSans-SemiCondensed.ttf') format('truetype') /* Safari, Android, iOS */
}
@font-face {
	font-family: 'NotoSansSCMedium';
	src: url('../assets//Fonts//Noto_Sans_SC/New/NotoSans-SemiCondensedMedium.ttf') format('truetype') /* Safari, Android, iOS */
}
@font-face {
	font-family: 'NotoSansSCSemiBold';
	src: url('../assets//Fonts//Noto_Sans_SC/New/NotoSans-SemiCondensedSemiBold.ttf') format('truetype') /* Safari, Android, iOS */
}
@font-face {
	font-family: 'NotoSansSCBold';
	src: url('../assets//Fonts//Noto_Sans_SC/New/NotoSans-SemiCondensedBold.ttf') format('truetype') /* Safari, Android, iOS */
}

body, h1, h2, h3, h4, h5 {
	font-family: 'NotoSans', 'Helvetica Neue' !important;
}



#root {
	height: 100%;
	min-height: 666px;
}
.height-100 {
	height: 100%;
}

.ui.container {
	/*min-width: 538px;*/
	max-height: 1000px;
}
.ui.container.pageMinWidth {
	min-width: 895px;
}

@media only screen and (max-width: 767px) {
	.ui.container {
		width: 933px !important;
	}
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
	.ui.container {
		width: 933px !important;
	}
}

.header-style {
	height: 63px;
}
.midnight-color {
	color: #144E5D;
}
.header-right-section {
	float: right;
	display: flex;
	margin: 5px auto;
	height: 100%;
}
.header-right-section .basic.button {
	margin-right: 20px;
	box-shadow: none;
}
.header-right-section .button i {
	margin: 0 !important;
}

.teamNameDropDown {
	display: block;
	flex: 4;
	border-right: 1px solid #ccc;
	margin-right: 6px;
	padding-right: 15px;
	font-family: 'NotoSansBold';
	font-size: 13px;
	color: #144E5D;
}
.languageDropDown {
	font-size: 12px;
	font-family: 'NotoSans';
	color: #144E5D;
	padding-left: 10px;
}

.header-Container {
	display: flex;
	flex-direction: row;
}
/* 0 1px 2px 0 rgba(34,36,38,.15); */
.header-Container .mainSegmentHeader {
	position: relative;
	width: calc(100% - 110px);
	background: #fff;
	box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
	padding: 15px 10px 15px 10px;
	height: 100%;
}

.header-Container .raisHandSegment {
	width: 48px;
	background: #fff;
	box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
	padding: 15px 10px 15px 10px;
	margin-left: 6.5px;
}
.chatSegment {
	position: relative;
	width: 48px;
	background: #fff;
	box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
	padding: 15px 10px 15px 10px;
	margin-left: 6.5px;
}

.customHeaderBtn {
	display: flex;
	cursor: pointer;
	width: 100%;
	height: 100%;
}
.customHeaderBtn i {
	margin: auto !important;
}




/* Checklist Style */
.checklist-header-style {
	height: 72px;
	width: calc(100% - 110px);
	background: #fff;
	/*box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
	border: 1px solid rgba(34,36,38,.15);*/
	margin-top: 5px;
}
.checklist-header-Container {
	display: flex;
	flex-direction: row;
	height: 100%;
	box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
	border: 1px solid rgba(34,36,38,.15);
}

.checklist-header-style .session-container {
	width: 150px;
	height: 100%;
	padding: 5px;
}
.session-container .grid,
.current-checklist-container .grid {
	margin: 0px !important;
	height: 100%;
}
.session-container .row,
.current-checklist-container .row {
	padding: 0px !important;
	margin: 0px !important;
}
.session-container .column,
.current-checklist-container .column {
	padding: 0px !important;
	margin: auto 0px !important;
}
.session-container p {
	font-family: 'NotoSansBold';
	font-size: 13px;
	text-align: center;
	color: #144E5D;
	line-height: 13px;
}
.session-container p.year {
	opacity: 0.5;
}
.session-container .cursor {
	cursor: pointer;
}
.checklistDesc {
	margin-top: 0px !important;
}
.checklistDesc p {
	font-size: 13px;
	color: #144E5D;
	opacity: 0.7;
	padding-right: 48px;
}
.checklistDescAr p {
	font-size: 13px;
	color: #144E5D;
	opacity: 0.7;
	padding-left: 48px;
}

.checklist-header-style .separator {
	width: 23px;
	height: 100%;
	margin: auto 0px;
	background-color: #DEE2E6;
	display: flex;
	cursor: pointer;
}
.checklist-header-style .separator img {
	margin: auto;
}

.session-container img {
	margin: auto;
}

.checklistTitle {
	color: #144E5D;
	font-size: 14px;
	line-height: 18px;
	font-family: 'NotoSansBold';
}
.checklistStatusImg {
	margin: 0 auto;
}

.current-checklist-container {
	position: relative;
	width: 100%;
}
.checklist-popup {
	position: absolute;
    top: 74px;
    right: 0px;
	width: 100%;
    max-height: 500px;
    border: 6px solid transparent;
    box-shadow: 0px 3px 2px #DEE2E6;
    z-index: 12;
	background-color: white;
	overflow-y: scroll;
}
.checklist-list-row {
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	border-bottom: 1px solid #DEE2E6;
}


.chatContainer {
	position: fixed;
	right: -250px;
	width: 250px;
	background-color: #144E5D;
	top: 0px;
	bottom: 0px;
	overflow-y: scroll;
	transition: right .3s ease;
}
.chatContainer.show {
	right: 0px;
}




.chatInputContainer {
	background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    height: 100px;
}
.chatInputContainer textarea {
	color: #fff;
	font-size: 12px;
	width: 100%;
	height: 100%;
	background-color: transparent;
}

.droppableField {
	width: 26px;
	height: 26px;
	border: 0.5px solid #EBEBEC;
	border-radius: 2px;
	margin-right: 8px;
	background-color: rgba(0, 0, 0, 0.15);
}
.droppableField.Ar {
	margin-left: 8px;
	margin-right: 0;
}
.scaleDown .droppableField {
	width: 22px !important;
	height: 22px !important;
}
.droppableField.whiteBG {
	background-color: #FFFFFF;
}
.droppableField .amountContainer {
	width: 100%;
	height: 100%;
	cursor: grab;
	/*border: 0.5px solid #EBEBEC;
	background-color: #FFFFFF;
	padding-bottom: 2px;*/
}
.droppableField .amountContainer .coinsContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column-reverse;
}

.coin-img {
	background-image: url(../assets/Images/coin-img.svg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 22px;
	height: 4px;
	margin: 0 auto;
}
.coin-img:last-child {
	background-image: url(../assets/Images/last-coin-img.svg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 22px;
	height: 6px;
	margin: 0 auto;
}
.scaleDown .coin-img {
	width: 18px !important;
	height: 3px !important;
}
.scaleDown .coin-img:last-child {
	width: 18px !important;
	height: 5px !important;
}


.counterContainer {
	width: 286px;
	height: 57px;
	position: absolute;
	top: -5px;
	left: 50%;
	margin-left: -140px;
	background-image: url(../assets//Images/counter-bg.png);
	background-size: cover;
	display: flex;
	flex-direction: column;
}
/*.moveLeft .counterContainer {
	left: 65% !important;
}*/
.counterContainerClipper {
	margin: auto;
}
.counter-title {
	display: inline-block;
	font-size: 12px;
	font-family: 'NotoSansBold';
}
.timer {
	font-size: 12px;
	font-family: 'NotoSansBold';
	color: #144E5D;
	text-align: center;
}


.chatNotifications {
	position: absolute;
	background-color: #FF0000;
	height: 18px;
	min-width: 18px;
	border-radius: 9px;
	color: #FFFFFF;
	font-weight: bold;
	font-size: 12px;
	text-align: center;
	line-height: 18px;
	top: calc(50% - 20px);
	right: calc(50% - 20px);
}



.mainSegmentHeader .ui.basic.grey.button, 
.mainSegmentHeader .ui.basic.grey.buttons .button {
	color: #144E5D!important;
	/*box-shadow: 0 0 0 1px #144E5D inset!important;*/
	box-shadow: none !important;
	border-radius: 4px;
	border: 1px solid #144E5D;
	font-family: 'NotoSans';
	font-size: 13px;
}
.ui.button {
	font-family: 'NotoSans', 'Helvetica Neue';
}

.customHeaderBtn img {
	margin: auto;
}


.current-checklist-container .column.checklistIconContainer {
	cursor: pointer; 
	margin-top: 10px !important;
}
.current-checklist-container .column.checklistStrategyIconContainer {
	cursor: pointer; 
	margin-top: 0px !important;
}
.current-checklist-container .column.checklistTitleContainer {
	margin-top: 10px !important;
}
.current-checklist-container .column.checklistStrategyTitleContainer {
	margin-top: 0px !important;
}


.ui.fullscreen.scrolling.modal {
	margin: auto !important;
	left: auto !important;
}
.ui.fullscreen.modal>.content>.image {
	margin: auto !important;
}


.loginView .title {
	font-family: 'NotoSansBold';
	font-size: 24px;
	margin-bottom: 30px;
}
.loginView .subtitle {
	float: right;
	font-family: 'NotoSans' !important;
	font-size: 13px;
}
.loginView .subtitleLink {
	color: #0C419A;
	text-decoration: underline;
	cursor: pointer;
}
.loginView label {
	font-family: 'NotoSansBold';
	font-size: 13px !important;
	margin-bottom: 0px !important;
}
.loginView input {
	height: 30px;
	padding: 0px 14px 0px 14px !important;
	font-family: 'NotoSans' !important;
	font-size: 13px !important;
	margin-top: 5px !important;
}
.loginView .note {
	font-family: 'NotoSans' !important;
	font-size: 13px !important;
}
.loginView .ui.primary.button, 
.loginView .ui.primary.buttons .button {
	background-color: #0C419A !important;
	float: right;
	margin-top: 20px;
	height: 31px;
	font-family: 'NotoSans';
	font-size: 12px !important;
	font-weight: 500 !important;
}

.validationMsg{
	font-family: 'NotoSans';
	font-size: 24px;
	text-align: center;
	line-height: 24px;
}

.rc-tooltip {
	z-index: 1000 !important;
}

.cashflowBtn {
	background-color: #144E5D !important;
	font-weight: normal !important;
	font-family: 'NotoSans' !important;
	font-size: 13px !important;
}


.presentationPopup.ui.modal {
	background: rgba(0, 0, 0, 0) !important;
	background-color: rgba(0, 0, 0, 0) !important;
}
.presentationPopup.ui.modal>.content {
	background: rgba(0, 0, 0, 0) !important;
	background-color: rgba(0, 0, 0, 0) !important;
}


.checklistNextBtn {
	font-size: 13px;
	color: #144E5D;
	background-image: url(../assets/Images/next-icon.svg);
	background-position-x: right;
	background-position-y: center;
	background-repeat: no-repeat;
	cursor: pointer;
	/*width: 42px;*/
	margin: auto;
	margin-top: 15px;
	text-align: center;
}



/* Guided Tour Popup */
.centerGuidedTourPopup {
	background-color: #144E5D;
	box-shadow: 0 5px 15px 0 rgba(0,0,0,.7);
	min-width: 300px;
	max-width: 500px;
	margin: auto 20px;
	padding: 1rem;
	color: #fff;
	border-radius: 20px;
	position: relative;
	min-height: 120px;
}
.centerGuidedTourPopup.staticWidth {
	width: 300px;
}
.centerGuidedTourPopup p {
	color: #fff !important;
	text-align: left;
}
.centerGuidedTourPopup .leftArrow {
	width: 0;
	height: 0;
	border-right:  20px solid #144E5D;
	border-bottom: 20px solid rgba(0,0,0,0);
	border-top: 20px solid rgba(0,0,0,0);
	position: absolute;
	top: calc(50% - 20px);
	left: -20px;
}

.sequenceGuidedTourPopup {
	background-color: #144E5D;
	box-shadow: 0 5px 15px 0 rgba(0,0,0,.7);
	width: 300px;
	margin: auto 20px;
	padding: 1rem;
	color: #fff;
	border-radius: 20px;
	position: absolute;
	min-height: 100px;
	right: -325px;
    z-index: 2;
    top: -28px;
}
.sequenceGuidedTourPopup2 {
	background-color: #144E5D;
	box-shadow: 0 5px 15px 0 rgba(0,0,0,.7);
	width: 300px;
	margin: auto 20px;
	padding: 1rem;
	color: #fff;
	border-radius: 20px;
	position: absolute;
	min-height: 100px;
	left: -35px;
    z-index: 2;
    top: -165px;
}
.sequenceGuidedTourPopup3 {
	background-color: #144E5D;
	box-shadow: 0 5px 15px 0 rgba(0,0,0,.7);
	width: 300px;
	margin: auto 20px;
	padding: 1rem;
	color: #fff;
	border-radius: 20px;
	position: absolute;
	min-height: 100px;
	left: 35px;
    z-index: 2;
    top: calc(50% - 50px);
}
.sequenceGuidedTourPopup4 {
	background-color: #144E5D;
	box-shadow: 0 5px 15px 0 rgba(0,0,0,.7);
	width: 300px;
	margin: auto 20px;
	padding: 1rem;
	color: #fff;
	border-radius: 20px;
	position: absolute;
	min-height: 100px;
	right: 35px;
    z-index: 40;
    top: 0px;
}
.sequenceGuidedTourPopup4.bottom {
	top: auto !important;
	bottom: -25px !important;
	right: 60px !important;
}
.sequenceGuidedTourPopup p, 
.sequenceGuidedTourPopup2 p,
.sequenceGuidedTourPopup3 p,
.sequenceGuidedTourPopup4 p {
	color: #fff !important;
	text-align: left;
}
.sequenceGuidedTourPopup .leftArrow,
.sequenceGuidedTourPopup3 .leftArrow {
	width: 0;
	height: 0;
	border-right:  20px solid #144E5D;
	border-bottom: 20px solid rgba(0,0,0,0);
	border-top: 20px solid rgba(0,0,0,0);
	position: absolute;
	top: calc(50% - 20px);
	left: -20px;
}
.sequenceGuidedTourPopup2 .bottomArrow {
	width: 0;
	height: 0;
	border-left: 20px solid rgba(0,0,0,0);
	border-right: 20px solid rgba(0,0,0,0);
	border-top: 20px solid #144E5D;
	position: absolute;
	bottom: -20px;
	left: 25px;
}
.sequenceGuidedTourPopup4 .rightArrow {
	width: 0;
	height: 0;
	border-left:  20px solid #144E5D;
	border-bottom: 20px solid rgba(0,0,0,0);
	border-top: 20px solid rgba(0,0,0,0);
	position: absolute;
	top: calc(50% - 20px);
	right: -20px;
}

.yellowBlock .sequenceGuidedTourPopup {
	top: 5px !important;
}
.yellowBlock .sequenceGuidedTourPopup .leftArrow {
	top: 20px !important;
}


.guidedTourPopup {
	background-color: #144E5D;
	box-shadow: 0 5px 15px 0 rgba(0,0,0,0.7);
	min-width: 300px;
	max-width: 300px;
	margin: 0 auto;
	padding: 1rem;
	position: absolute;
	top: 100%;
	color: #fff;
	z-index: 990;
	margin-top: 10px;
	border-radius: 20px;
	left: calc(50% - 150px)
}
.guidedTourPopupTop {
	background-color: #144E5D;
	box-shadow: 0 5px 15px 0 rgba(0,0,0,0.7);
	min-width: 300px;
	max-width: 300px;
	margin: 0 auto;
	padding: 1rem;
	position: absolute;
	bottom: calc(100% + 20px);
	color: #fff;
	z-index: 990;
	margin-top: 10px;
	border-radius: 20px;
	left: calc(50% - 150px)
}
.guidedTourPopup.right {
	right: 0;
	left: auto;
}
.guidedTourPopup.right2 {
	right: -100px;
	left: auto;
	width: 100px !important;
	max-width: 100px !important;
}
.guidedTourPopup h4, .guidedTourPopupTop h4 {
	margin-top: 0px;
}
.guidedTourPopup p, .guidedTourPopupTop p {
	color: #fff !important;
	text-align: left;
	font-family: 'NotoSans';
	line-height: 19px;
}

.guidedTourPopup .upArrow {
	width: 0;
	height: 0;
	border-left: 20px solid rgba(0,0,0,0);
	border-right: 20px solid rgba(0,0,0,0);
	border-bottom: 20px solid #144E5D;
	position: absolute;
	top: -20px;
}
.guidedTourPopupTop .downArrow {
	width: 0;
	height: 0;
	border-left: 20px solid rgba(0,0,0,0);
	border-right: 20px solid rgba(0,0,0,0);
	border-top: 20px solid #144E5D;
	position: absolute;
	bottom: -20px;
	left: calc(50% - 20px)
}

.guidedTourPopup .upArrow {
	left: calc(50% - 20px);
}
.guidedTourPopup .upArrow.custom {
	left: calc(50% - 35px) !important;
}
.guidedTourPopup.right .upArrow {
	right: 5%;
	left: auto !important;
}

.guidedTourPopup.accountsReceivable .upArrow {
	right: 10%;
	left: none;
}

.nextBtnContainer, .prevBtnContainer {
    border: 3px solid #fff;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 35px;
    font-size: 14px;
}
.nextBtnContainer2 {
	border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    height: 35px;
    width: 90px;
    text-align: center;
    line-height: 35px;
	font-size: 14px;
	float: right;
}
.nextBtnContainer {
	float: right;
	background-image: url("../assets/Images/arrow-right.svg");
	background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
}
.prevBtnContainer {
	float: left;
	background-image: url("../assets/Images/arrow-left.svg");
	background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
}

.nextBtnContainer.opacity {
	opacity: 0.4;
}

.teamNameDropDown .menu.transition {
	z-index: 1000;
}

.redIndicator {
	position: absolute;
	width: 50px;
	height: 50px;
	border: 4px dashed #FF0000;
    border-radius: 25px;
    top: -50%;
    left: -40%;
    animation: spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.redIndicator2 {
	position: absolute;
	width: 50px;
	height: 50px;
	border: 4px dashed #FF0000;
    border-radius: 25px;
    top: -50%;
    right: 30px;
	animation: spin 4s linear infinite;
	z-index: 5;
}

.guidedtour-intro-container .closeBrowserTxt {
    background-color: transparent;
    color: inherit;
    font-size: 12px;
    height: 31px;
	line-height: 31px;
}

.clear {
	clear: both;
}

.repeat-guided-tour {
	cursor: pointer;
}