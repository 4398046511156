.optionalCashflowTitle {
	margin-top: 20px;
	font-size: 24px;
	font-family: 'NotoSans';
	color: #144E5D;
	margin-bottom: 30px;
	line-height: 24px;
}
.cashflowPointer {
	background-image: url(../assets/Images/cashflow-pointer.svg);
	position: absolute;
	z-index: 10;
	width: 68px;
	height: 83px;
	cursor: pointer;
}
.stockOfMatrialsContainer .cashflowPointer {
	top: -70px;
	left: -22px;
}
.manufacturingContainer .cashflowPointer {
	top: -70px;
	left: -50px;
}
.manufacturingContainer .cashflowPointer.second {
	top: -70px;
	right: -50px !important;
	left: auto;
}
.rightBlocksContainers .cashflowPointer {
	right: 0px;
	top: -10px;
}
.leftBlockContainer .cashflowPointer {
	left: 30px;
	bottom: 45px;
}
.plantField.cashflowPointer {
	bottom: 25px;
	left: -16px;
}
.plantText.cashflowPointer {
	bottom: 10px;
}
.overheadBlock .cashflowPointer,
.developmentBlock .cashflowPointer {
	bottom: 15px !important;
	left: -18px !important;
	right: auto !important;
	top: auto !important;
}
.orangeBlock .cashflowPointer,
.lendersBlock .cashflowPointer {
	bottom: 25px;
	left: -16px;
}

.cashflowPointer .circle {
	background-color: #ffffff;
	width: 34px;
	height: 34px;
	border-radius: 17px;
	position: relative;
	top: 13px;
	left: 17px;
	color: #144E5D;
	font-size: 18px;
	font-family: 'NotoSansBold';
	line-height: 34px;
	text-align: center;
}
.cashflowPointer .circle-rtl {
	background-color: #ffffff;
	width: 34px;
	height: 34px;
	border-radius: 17px;
	position: relative;
	top: 13px;
	right: 17px;
	color: #144E5D;
	font-size: 18px;
	font-family: 'NotoSansBold';
	line-height: 34px;
	text-align: center;
}
.cashflowPointer .circle.finished {
	background-color: #144E5D !important;
	color: #1DFFE5 !important;
}


.cashflow-modal-overlay {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: rgba(0, 0, 0, 0.65);
	z-index: 12;
}
.cashflow-modal-box {	
	background-color: #144E5D;
	color: #ffffff;
	width: 100%;
	height: 100%;
	padding: 20px;
	position: relative;
}
.cashflow-marker {
	background-image: url(../assets/Images/cashflow-pointer.svg);
	position: absolute;
	z-index: 10;
	width: 68px;
	height: 83px;
	cursor: pointer;
	left: 50%;
	top: -40px;
	margin-left: -34px
}
.cashflow-marker .circle {
	background-color: #ffffff;
	width: 34px;
	height: 34px;
	border-radius: 17px;
	position: relative;
	top: 13px;
	left: 17px;
	color: #144E5D;
	font-size: 18px;
	font-family: 'NotoSansBold';
	line-height: 34px;
	text-align: center;
}
.cashflow-question-title {
	font-size: 24px; 
	color: rgba(255, 255, 255, 0.87);
	font-family: 'NotoSansBold';
	text-align: center;
	margin-top: 40px;
	margin-bottom: 20px;
}
.cashflow-question-text {
	font-size: 16px;
	font-family: 'NotoSans';
	color: rgba(255, 255, 255, 0.87);
	text-align: center;
}
.cashflow-content {
	margin-top: 40px;
}
.answer-title {
	font-size: 16px;
	font-family: 'NotoSansBold';
	color: rgba(255, 255, 255, 0.87);
	margin: auto;
}

.cashflow-content .column {
	display: flex !important;
}
.cashflow-content .column img {
	cursor: pointer;
	margin: auto;
}
.cashflow-content .column .ui.button {
	margin: auto;
	background: none;
	border: 1px solid #ffffff !important;
	color: #ffffff !important;
}


.correctAnswerFlag {
	position: absolute !important;
	top: 0px;
	right: 8px;
}

.boardCelemiLogo .ui.button {
	position: absolute !important; 
	right: 0px; 
	bottom: 0px;
	background-color: #144E5D;
	color: #ffffff;
	font-size: 13px;
	font-family: 'NotoSans' !important;
    font-weight: normal !important;
}

.cashflowPointer .circle.success, .cashflowPointer .circle.correct {
	width: 34px;
	height: 34px;
	background-color: #ffffff;
	background-image: url(../assets/Images/cashflow-correct-answer.svg);
	background-repeat: no-repeat;
	background-position: center;
	color: rgba(0, 0, 0, 0);
}
.cashflowPointer .circle.wrong {
	width: 34px;
	height: 34px;
	background-color: #ffffff;
	background-image: url(../assets/Images/cashflow-wrong-answer.svg);
	background-repeat: no-repeat;
	background-position: center;
	color: rgba(0, 0, 0, 0);
}
/*.cashflowPointer .circle:after {
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	z-index: 10;
	background-image: url(../assets/Images/cashflow-wronge-answer.svg);
}*/