.chatContainer {
	position: fixed;
	right: -250px;
	width: 250px;
	background-color: #144e5d;
	top: 0px;
	bottom: 0px;
	transition: right 0.3s ease;
  }
  .chatContainer.show {
	right: 0px;
  }
  .chat-header {
	box-shadow: 0 0 2rem rgba(0, 0, 0, 0.075),
	  0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.1);
  }
  .chatGrid {
	position: relative;
	height: 100%;
	width: 100%;
	margin: 0 !important;
	padding: 0 !important;
	display: flex;
	flex-direction: column;
  }
  .chatMessagesContainer {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	box-shadow: inset 0 2rem 2rem -2rem rgba(0, 0, 0, 0.05),
	  inset 0 -2rem 2rem -2rem rgba(0, 0, 0, 0.05);
	flex: 1;
  }
  
  .ChatBox {
	box-sizing: border-box;
	padding: 0.5rem 1rem;
	position: relative;
	margin: 10px 4px;
	border-radius: 1.125rem 1.125rem 1.125rem 0;
	width: 200px;
	box-shadow: 0 0 2rem rgba(0, 0, 0, 0.075),
	  0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.1);
  }
  
  .chatMessagesContainer .userName {
	font-size: 11px;
	margin-bottom: 5px;
	font-weight: bold;
  }
  .chatMessagesContainer p {
	font-size: 12px;
	margin-bottom: 9px;
  }
  .msgDate {
	position: absolute;
	font-size: 9px;
	bottom: 0;
	right: 9px;
	opacity: 0.7;
  }
  
  .leftChatBox {
	background: white;
	color: #144e5d !important;
	align-self: flex-start;
  }
  .rightChatBox {
	margin: 1rem 1rem 1rem auto;
	border-radius: 1.125rem 1.125rem 0 1.125rem !important;
	background: #d1d9db;
	color: #144e5d;
	width: 200px;
	align-self: flex-end;
  }
  .rightChatBox .userName {
	text-align: end;
  }
  .ChatInput {
	padding: 10px 7px;
	border-top: 1px solid dimgrey;
	box-shadow: 0 0 2rem rgba(0, 0, 0, 0.075),
	  0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.1);
  }
  
  .ChatInput > form.ui.form .fields > .field {
	margin: 0 !important;
	padding: 0 !important;
  }
  .ChatInput > form.ui.form > .fields {
	margin: 0 !important;
	padding: 0 !important;
  }
  
  .chatMsg {
	/*line-break: anywhere;*/
  }
  