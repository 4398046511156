.ui.bottom.attached.segment.active.tab {
	box-shadow: 0 3px 6px rgb(0, 0, 0, 16%);
}

.ui.attached.tabular.menu > a.item {
	font-family: "NotoSansBold";
	color: #144e5d;
	font-size: 13px;
	padding: 0 35px;
	text-align: center;
	background: none #fff;
	border-top-width: 1px;
	background-color: #d1d9db !important;
	border-radius: 7px 7px 0px 0px !important;
}

.ui.attached.tabular.menu > a.item.opaque { opacity: 0.6; }

.ui.attached.tabular.menu > a.item.active {
	background-color: white !important;
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
	opacity: 1;
}
.businessReviewContiner {
	position: relative;
}
.ui.attached.tabular.menu {
	width: fit-content !important;
	box-shadow: 0 3px 6px rgb(0, 0, 0, 16%) !important;
	border-radius: 9px 9px 0 0;
}
.businessReviewTabs {
	height: 628px;
	margin: 29px 32px 0 32px;
	font-family: "NotoSans";
	color: white;
}

.indicator1stCol .text p {
	font-family: "NotoSansBold";
}
.indicator1stCol .text {
	font-size: 13px !important;
	font-family: "NotoSans";
	line-height: 1.5;
	width: 93%;
	margin: calc(2rem - .14285714em) 0 1rem;
}


.closeSign {
	position: absolute;
	top: 1%;
	cursor: pointer;
}

.closeSign.ltr{
	right: 35px;
}
.closeSign.rtl{
	left: 35px;
}

.dupontCell {
	text-align: center;
	width: 132px;
}

.cellTitle {
	white-space: nowrap;
	width: 100% !important;
	font-size: 12px;
	font-family: "NotoSansSCMedium";
	color: white;
	padding-top: 5px;
}
.dupontCell .row {
	padding-top: 0 !important;
}
.dupontCell .row:nth-child(1) {
	height: 32.57px;
}
.dupontCell .row:nth-child(2) {
	margin-bottom: -13px;
	font-size: 14px;
	font-family: "NotoSansBold";
	align-items: center;
}
.cellField {
	width: 63px;
	height: 30px;
	margin-left: -12px;
	border-color: transparent !important;
	font-family: 'NotoSans';
}
.cellFieldAr {
	width: 63px;
	height: 30px;
	margin-right: -12px;
	border-color: transparent !important;
	font-family: 'NotoSans';
}
.cellField:focus {
	outline: 0 !important;
}

.cellGroup {
	width: 132px !important;
	height: fit-content;
}
.cellGroup.n2dCol {
	margin-top: 40px !important;
}
.cellGroup.r3dCol {
	margin-top: 88px !important;
}
.cellGroup.t4hCol {
	margin-top: 40px !important;
}

.Branching ~ .Branching {
	margin-top: 40px !important;
}

.Branching {
	width: 18.3px !important;
	top: 3.9%;
	padding: 0 !important;
}
.businessReviewContiner .minus {
	position: absolute;
	top: 37% !important;
	color: #144e5d;
	opacity: 0.5;
	font-size: 12px;
	left: -35px;
}
.businessReviewContiner .minusAr {
	position: absolute;
	top: 37% !important;
	color: #144e5d;
	opacity: 0.5;
	font-size: 12px;
	right: -35px;
}
.cellGroup {
	position: relative;
}

.cellArrow {
	position: absolute;
	margin-left: 49px;
}
.cellArrowAr {
	position: absolute;
	margin-right: 49px;
}

.Year1b4 {
	position: absolute;
	top: -20px;
	color: #144e5d;
	opacity: 0.5;
	display: flex;
	font-size: 12px;
	text-align: center;
	line-height: 0;
}

.Year1b4 > div {
	margin: 0 6px;
}
  

.colEquation p:first-child {
	margin-bottom: 8px;
	font-size: 13px;
}

.Year1b4 p {
	margin-bottom: 1em !important;
}

.nextBtn {
	position: absolute;
	font-size: 14px;
	bottom: 80px;
	cursor: pointer;
	color: #144e5d;
	border: none;
	background-color: transparent;
}

.nextBtn.ltr{
	right: 65px;
}

.nextBtn.rtl {
	left: 65px;
}

.paddingRight{
	padding-right: 15px;
}

.paddingRight20{
	padding-right: 20px;
}